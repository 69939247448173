import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { Product } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";

@Component({
  selector: 'app-product-box-one',
  templateUrl: './product-box-one.component.html',
  styleUrls: ['./product-box-one.component.scss']
})
export class ProductBoxOneComponent implements OnInit {

  @Input() product: any;
  // public products: any[] = [];
  @Input() currency: any = this.productService.Currency; // Default Currency 
  @Input() thumbnail: boolean = false; // Default False 
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;
  @Input() attributes:any;
  
  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;

  public ImageSrc : string

  constructor(private productService: ProductService) { }

  ngOnInit(): void {
    //console.log('productssssss --->',this.product)
    // this.products = this.product;
    if(this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }

    if(this.product && typeof(this.product.zf)=='string'){
      this.product.zf = JSON.parse(this.product.zf);
    }
    this.buildViewModel();
    console.log('this.product', this.product)
    
  }

  buildViewModel(){
    this.product.view ={
      url: this.ParseImage(this.product.zf),
      price:this.findLowPriceToShow(),
      color: this.splitColors()
    }
  }

  splitColors(){
    //todo need to implement the functionality to store the color code in admin to display color 
    const model ={hasColor:false, models:[]};
    const color = (this.attributes||[]).find((obj)=>((obj.name.toLowerCase() === 'color') && obj.is_active));
    // if(color){
    //   model.hasColor = true;
    //   for(){

    //   }
    // }

    return model;
  }

  findLowPriceToShow(){
    let preIndex:any=0;

    if(this.product.variants && this.product.variants.length){

      for(let index in this.product.variants||[]){
        const variant = this.product.variants[index]
        
        if(parseInt(index) != 0){
          if(this.product.variants[preIndex].sp > variant.sp ){
            preIndex = index;
          }
        }
      }
      return this.product.variants[preIndex];
    }

    return {sp:0}

  }

  ParseImage(imageObj){
    //console.log('imageObj', imageObj)
    if(imageObj){
      const file = imageObj.find((obj)=>(obj.default))

      if(file){
        return file.url;
      }else{
        return imageObj.length?imageObj[0].url:'assets/images/product/placeholder.jpg';
      }
    } else{
      return;
    }
  }

  getUrl(text){
    return text.replace(/[\(\)]/g, '-').toLowerCase();
  }
  
  // Get Product Color
  Color(variants) {
    const uniqColor = [];
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        uniqColor.push(variants[i].color)
      }
    }
    return uniqColor
  }

  // Change Variants
  ChangeVariants(color, product) {
    product.variants.map((item) => {
      if (item.color === color) {
        product.images.map((img) => {
          if (img.image_id === item.image_id) {
            this.ImageSrc = img.src;
          }
        })
      }
    })
  }

  // Change Variants Image
  ChangeVariantsImage(src) {
    this.ImageSrc = src;
  }

  addToCart(product: any) {
    this.productService.addToCart(product);
  }

  addToWishlist(product: any) {
    this.productService.addToWishlist(product);
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }

}
