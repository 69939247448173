// import {Message}     from '@angular/compiler/src/i18n/i18n_ast';
import {Injectable}  from '@angular/core';
import {environment} from '../../environments/environment';

const baseURL = environment.url;

@Injectable({
    providedIn: 'root'
})

export class CommonService{

	static saveModel(ctrl: any, model?, baseUrl?,isUpdateModel?){

		let modelData = model ? model : ctrl.model;
		const module  = baseUrl ? baseUrl : ctrl.baseUrl;
		if(modelData?.id){
			return ctrl.storageS.update(modelData.id, modelData, module)
				.subscribe(
					(message: any) => {
						console.log(message);
						let msg = 'Successfully Updated';
					},
					(error: any) => {
						console.log(error);
						let msg = 'Error';
					}
				);
		} else{
			return ctrl.storageS.create(modelData, module).subscribe(
				(message: any) => {
					console.log(message);
					if(isUpdateModel){
						model.id = message.data[0].id;
					}
					let msg = 'Successfully Added';
				},
				(error: any) => {
					console.log(error);
					let msg = 'Error';
				}
			);
		}
	}

	static buildServerPath(model){
		if((model.zf !== undefined)){
			if(typeof model.zf === 'string'){
				model.zf = JSON.parse(model.zf);
			}
			if(model.zf && model.zf.length){
				for(let zf of model.zf){
					if(zf && (!zf.url.includes(baseURL))){
						zf.url = `${baseURL}${zf.url}${zf.nm}`;
						zf.a   = 0;
					}
				}
				model.zf[0].a = 1;
			} else{
				model.zf = [];
			}
		}
	}

	static trimServerPath(model){
		if((model.zf !== undefined)){
			if(typeof model.zf === 'string'){
				model.zf = JSON.parse(model.zf);
			}
			if(model.zf && model.zf.length){
				for(let zf of model.zf){
					zf.url = zf.url.replace(baseURL, '');
					zf.url = zf.url.replace(zf.nm, '');
					delete zf.a;
				}
			} else{
				model.zf = null;
			}
		}
	}

	static save(module){
		return module.serverService.create(module.model, module.baseUrl).subscribe(
			(message: any) => {
			},
			(error) => {
				console.log(error);
			}
		);
	}

    static fetchAll(module){
		return new Promise((resolve, reject)=>{
			module.dynamicContent = [];
			module.serverService.getAll(module.baseUrl).subscribe(
				(response) => {
					console.log(response.message);
					module.dynamicContent = response.data;
					resolve(true);
				},
				(error) => {
					console.log(error);
					resolve(false)
				}
			);
		})
	}

	static fetchById(module){
		return new Promise((resolve, reject)=>{
			module.dynamicContent = [];
			module.serverService.getById(module.baseUrl, module.productId).subscribe(
				(response) => {
					console.log(response.message);
					module.dynamicContent = response.data;
					resolve(true);
				},
				(error) => {
					console.log(error);
					resolve(false)
				}
			);
		})
	}

	static fetchOrdersById(module){
		return new Promise((resolve, reject)=>{
			module.dynamicContent = [];
			module.serverService.getById(module.baseUrl, module.userData.id).subscribe(
				(response) => {
					console.log(response.message);
					module.dynamicContent = response.data;
					resolve(true);
				},
				(error) => {
					console.log(error);
					resolve(false)
				}
			);
		})
	}

	static fetchAddressById(module){
		return new Promise((resolve, reject)=>{
			module.dynamicContent = [];
			module.serverService.getAddressById(module.baseUrl, module.userData.id).subscribe(
				(response) => {
					console.log(response.message);
					module.dynamicContent = response.data;
					resolve(true);
				},
				(error) => {
					console.log(error);
					resolve(false)
				}
			);
		})
	}

	
	static deleteById(module, id){
		return new Promise((resolve, reject)=>{
			module.dynamicContent = [];
			module.serverService.deleteById(module.baseUrl, id).subscribe(
				(response) => {
					console.log(response.message);
					module.dynamicContent = response.data;
					resolve(true);
				},
				(error) => {
					console.log(error);
					resolve(false)
				}
			);
		})
	}

	static fetchAllCartItems(module){
		return new Promise((resolve, reject)=>{
			module.dynamicContent = [];
			module.serverService.getAllCartItems(module.baseUrl, module.userData.id).subscribe(
				(response) => {
					console.log(response.message);
					module.dynamicContent = response.data;
					resolve(true);
				},
				(error) => {
					console.log(error);
					resolve(false)
				}
			);
		})
	}

	static fetchAllWishItems(module){
		return new Promise((resolve, reject)=>{
			module.dynamicContent = [];
			module.serverService.getAllWishItems(module.baseUrl, module.userData.id).subscribe(
				(response) => {
					console.log(response.message);
					module.dynamicContent = response.data;
					resolve(true);
				},
				(error) => {
					console.log(error);
					resolve(false)
				}
			);
		})
	}

	static fetchAllLocation(module, location){
		return new Promise((resolve, reject)=>{
			module.dynamicContent = [];
			module.serverService.getAllLocation(module.baseUrl, location).subscribe(
				(response) => {
					console.log(response.message);
					module.dynamicContent = response.data;
					resolve(true);
				},
				(error) => {
					console.log(error);
					resolve(false)
				}
			);
		})
	}

	static searchProducts(module, req){
		return new Promise((resolve, reject)=>{
			module.dynamicContent = [];
			module.serverService.searchProducts(module.baseUrl, req).subscribe(
				(response) => {
					console.log(response.message);
					module.dynamicContent = response.data;
					resolve(true);
				},
				(error) => {
					console.log(error);
					resolve(false)
				}
			);
		})
	}

	static fetchBySlug(module, slug){
		return new Promise((resolve, reject)=>{
			module.dynamicContent = [];
			module.serverService.getBySlug(module.baseUrl, slug).subscribe(
				(response) => {
					console.log(response.message);
					module.dynamicContent = response.data;
					resolve(true);
				},
				(error) => {
					console.log(error);
					resolve(false)
				}
			);
		})
	}

	static fetchBySlugLocation(module, slug, location){
		return new Promise((resolve, reject)=>{
			module.dynamicContent = [];
			module.serverService.getBySlugLocation(module.baseUrl, slug, location).subscribe(
				(response) => {
					console.log(response.message);
					module.dynamicContent = response.data;
					resolve(true);
				},
				(error) => {
					console.log(error);
					resolve(false)
				}
			);
		})
	}

	static register(module){
		return new Promise((resolve, reject)=>{
			module.serverService.register(module.model).subscribe(
				(response) => {
					module.dynamicContent = response;
					resolve(true);
				},
				(error) => {
					module.dynamicContent = error;
					console.log(error);
					resolve(false);
				}
			);
		})
	}

	static subscriber(module){
		
		return new Promise((resolve, reject)=>{
			module.serverService.subscriber(module.model).subscribe(
				(response) => {
					module.dynamicContent = response;
					resolve(true);
				},
				(error) => {
					console.log(error);
					resolve(false);
				}
			);
		})
	}

	static registerSeller(module){
		return new Promise((resolve, reject)=>{
			module.serverService.registerSeller(module.model).subscribe(
				(response) => {
					module.dynamicContent = response;
					resolve(true);
				},
				(error) => {
					console.log(error);
					resolve(false);
				}
			);
		})
	}

	static login(module){
		return new Promise((resolve, reject)=>{
			module.serverService.login(module.model).subscribe(
				(response) => {
					module.dynamicContent = response;
					resolve(true);
				},
				(error) => {
					module.dynamicContent = error;
					resolve(false);
				}
			);
		})
	}

	static edit(module){
		return new Promise((resolve, reject)=>{
			module.serverService.edit(module.model, module.baseUrl).subscribe(
				(response) => {
					resolve(true);
				},
				(error) => {
					resolve(false);
				}
			);
		})
	}

	static status_update(module, id){
	console.log(module)
		return new Promise((resolve, reject)=>{
		
			console.log(module.baseUrl);
			module.serverService.status_update(id, module.data, module.baseUrl).subscribe(
			
				(response)=>{
					resolve(true);
					console.log(response)
				},
				(error)=>{
					resolve(false)
					console.log(error)
					module.messageService.add('Error');
				}
			)
		})
	}

	static cartQtyupdateById(module, id){
	console.log(module)
		return new Promise((resolve, reject)=>{
		
			console.log(module.baseUrl);
			module.serverService.cartQtyupdateById(id, module.data, module.baseUrl).subscribe(
			
				(response)=>{
					resolve(true);
					console.log(response)
				},
				(error)=>{
					resolve(false)
					console.log(error)
					module.messageService.add('Error');
				}
			)
		})
	}
}
